import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as discountRuleService from 'services/discount'
import actions from 'redux/discount/actions'
import DiscountRule from 'model/discount/DiscountRule'
import DiscountRuleList from 'model/discount/DiscountRuleList'
import ListDiscountRuleReq from 'model/discount/req/ListDiscountRuleReq'
import CreateDiscountRuleReq from 'model/discount/req/CreateDiscountRuleReq'
import UpdateDiscountRuleReq from 'model/discount/req/UpdateDiscountRuleReq'

export function* LIST_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(discountRuleService.listDiscountRule, ListDiscountRuleReq.of(payload))
  if (data) {
    yield put({
      type: 'discount/SET_STATE',
      payload: {
        list: DiscountRuleList.fromData(data),
        detail: DiscountRule.empty()
      }
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { ruleId } = payload
  const data = yield call(discountRuleService.readDiscountRule, ruleId)
  if (data) {
    yield put({
      type: 'discount/SET_STATE',
      payload: {
        detail: DiscountRule.fromData(data)
      }
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(discountRuleService.createDiscountRule, CreateDiscountRuleReq.of(payload))
  if (data) {
    yield put({
      type: 'discount/ADD_STATE',
      payload: {
        rule: DiscountRule.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(discountRuleService.updateDiscountRule, UpdateDiscountRuleReq.of(payload))
  if (success) {
    yield history.push('/catalog/discount/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { ruleId } = payload
  const success = yield call(discountRuleService.deleteDiscountRule, ruleId)
  if (success) {
    yield put({
      type: 'discount/DELETE_STATE',
      payload: { ruleId }
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CLEAR_DISCOUNT() {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      list: DiscountRuleList.empty(),
      detail: DiscountRule.empty()
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_DISCOUNT, LIST_DISCOUNT),
    takeEvery(actions.READ_DISCOUNT, READ_DISCOUNT),
    takeEvery(actions.CREATE_DISCOUNT, CREATE_DISCOUNT),
    takeEvery(actions.UPDATE_DISCOUNT, UPDATE_DISCOUNT),
    takeEvery(actions.DELETE_DISCOUNT, DELETE_DISCOUNT),
    takeEvery(actions.CLEAR_DISCOUNT, CLEAR_DISCOUNT),
  ])
}
